@import "/src/styles/mixin";

.item {
  width: 23%;
  @media only screen and (min-width: 1366px) and (max-width: 1439.98px) {
    width: 22%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
    width: 30%;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 47%;
  }
}

.link {
  @include font14Bold;
  position: relative;
  display: block;
  max-width: 346px;

  .name {
    position: absolute;
    top: 16px;
    left: 16px;
    @include font14;
    line-height: 19px;
    max-width: 114px;

    @media screen and (max-width: 460px) {
      max-width: 74px;
      top: 9px;
      left: 9px;

      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
    }
  }

  .img {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-width: 346px;
    margin-bottom: 13px;
    border-radius: var(--border-radius-small);
    @media screen and (max-width: 460px) {
      margin-bottom: 0;
    }
  }
}


.depth {
  &List {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &Link {
    @include font14;
    @include lineHover;

    @media screen and (max-width: 460px) {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    @media screen and (max-width: 460px) {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
    }
  }
}

.more {
  @include font14;
  color: var(--grey-third);

  @media screen and (max-width: 460px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  @media screen and (max-width: 340px) {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }
}
